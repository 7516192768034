import {IBaseEntity, BaseEntity} from 'app/blocks/model/base-entity.model';

export interface IReasonCode extends IBaseEntity {
    id?: number;
    sequenceNo?: number;
}

export class ReasonCode extends BaseEntity implements IReasonCode {
    public id?: number;
    public sequenceNo?: number;

    constructor(reasonCode?) {
        super();
        if (reasonCode) {
            this.id = reasonCode.id;
            this.sequenceNo = reasonCode.sequenceNo;
        } else {
        }
    }

    get discriminator(): any {
        return this.id;
    }

    clone(): ReasonCode {
        return new ReasonCode(this);
    }
}
